import React from 'react'
import _ from 'lodash'

import RaccAlertMessage from 'Shared/racc_alert_message'

type errorMessagesProps = {
  messages: string | string[]
}

const ErrorMessages = ({ messages }: errorMessagesProps): JSX.Element => {
  const renderMessages =
    typeof messages === 'object'
      ? _.map(messages, (msg: string): JSX.Element => {
          return (
            <div key={msg} data-testid='error_messages'>
              {msg}
            </div>
          )
        })
      : messages && <div data-testid='error_messages'>{messages}</div>

  return <RaccAlertMessage type='error'>{renderMessages}</RaccAlertMessage>
}

export default ErrorMessages
