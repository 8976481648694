import React, { useRef, useEffect, RefObject } from 'react'
import _ from 'lodash'

import RaccAlertMessage from 'Shared/racc_alert_message'

type validationMessagesProps = {
  messages: string | string[]
  autoFocusOnError?: boolean
}
const ValidationMessages = ({
  messages,
  autoFocusOnError = false,
}: validationMessagesProps): JSX.Element => {
  const messagesElement = useRef<HTMLDivElement>(null)

  const scrollToRef = (ref: RefObject<HTMLDivElement>): void =>
    window.scrollTo(0, ref.current.offsetTop)

  useEffect((): void => {
    if (autoFocusOnError) {
      scrollToRef(messagesElement)
    }
  }, [messages, autoFocusOnError])

  return (
    <div ref={messagesElement}>
      <RaccAlertMessage type='error'>
        <h3 className='c-alert__heading'>
          Please correct the following errors:
        </h3>
        <ul className='c-alert__list'>
          {_.map(messages, (msg: string): JSX.Element => {
            return (
              <li key={msg} data-testid='validation_message'>
                {msg}
              </li>
            )
          })}
        </ul>
      </RaccAlertMessage>
    </div>
  )
}

export default ValidationMessages
