import React, { ReactNode } from 'react'
import alertTypes from 'Shared/racc_alert_message/constants/alert_message_types'

type raccAlertMessageProps = {
  type: string
  children: ReactNode
}
const RaccAlertMessage = ({
  type,
  children,
}: raccAlertMessageProps): JSX.Element => {
  let alertClass: string
  let dataTestid: string
  switch (type) {
    case alertTypes.SUCCESS:
      alertClass = 'c-alert--success'
      dataTestid = 'success_message'
      break
    case alertTypes.NOTICE:
      alertClass = 'c-alert--notice'
      dataTestid = 'notice_message'
      break
    default:
      alertClass = 'c-alert--error'
      dataTestid = 'error_message'
  }

  return (
    <div className={'c-alert ' + alertClass} data-testid={dataTestid}>
      {children}
    </div>
  )
}

export default RaccAlertMessage
